// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import { StatusCell } from './StatusCell'

const companyColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Company Name' className='min-w-50px' />
    ),
    id: 'companyName',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].companyName} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Email Domain' className='min-w-50px' />,
    id: 'emailDomain',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].emailDomain} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Phone' className='min-w-50px' />,
    id: 'phone',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].phone} />,
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Tax' className='min-w-50px' />,
  //   id: 'tax',
  //   Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].tax} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='No Of Employees' className='min-w-50px' />,
    id: 'numberOfEmployees',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].numberOfEmployees} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Company description' className='min-w-50px' />,
    id: 'description',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].description} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Enrolled Date' className='min-w-50px' />,
    id: 'enrolledDate',
    // Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].createdAt} />,
    Cell: ({ ...props }) => {
      const date = new Date(props.data[props.row.index].createdAt);
      const formattedDate = date.toLocaleDateString('en-US');
      return <TitleCell userObj={formattedDate} />;
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-50px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell userObj={props.data[props.row.index]} from="company"/>,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  },
]

export { companyColumns }
